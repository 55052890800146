<template>
  <!-- 列表 -->
  <a-table
    class="table-view"
    :data-source="dataSource"
    :columns="columns"
    :row-key="record => record.id"
    :loading="isLoading"
    :pagination="false"
  >
    <!-- 自定义行内容 -->
    <template #bodyCell="{ column, record, index }">
      <!-- 剧名 -->
      <template v-if="column.key === 'content'">
        <div v-for="(plays, ind) in record.content" :key="ind" class="play-name">
          <span v-for="(play, i) in plays" :key="i">
          <!-- 名称 -->
            {{ play.name }}
            <!-- 分隔符 -->
            <span class="gap" v-if="plays.length > i + 1">|</span>
          </span>
        </div>
      </template>
      <!-- 操作 -->
      <template v-if="column.key === 'operation'">
      <!-- 编辑 -->
        <a @click="handleAdd(record)">编辑</a>
        <!-- 删除  -->
        <!-- <a-popconfirm
          title="确定要删除吗？"
          @confirm="handleDelete(record)"
        >
          <a style="margin-left: 8px;">删除</a>
        </a-popconfirm> -->
      </template>
    </template>
  </a-table>
  <!-- 新增、编辑抽屉 -->
  <Add ref="RefAdd" @success="getFrontSourceList"></Add>
</template>

<script setup>
import { onBeforeMount, ref, reactive } from 'vue'
import { Platforms, FrontSource} from '@/utils/constantList'
import { message } from 'ant-design-vue'
import Add from './Add'
import { frontSourceList, frontSourceSort, frontSourceDelete } from '@/api/operate'

// 新增、编辑组件实例
let RefAdd = ref(null)
// props
const props = defineProps({
  platform: Number
})
// source类型
const source = FrontSource.special_price
// 加载
let isLoading = ref(false)
// 数据源
let dataSource = ref([])
// 列数据源（付费转化率、权重本期不做）
let columns = ref([
  {
    title: '板块名称',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '短剧',
    dataIndex: 'content',
    key: 'content'
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation'
  }
])

// 钩子函数
onBeforeMount(() => {
  // 获取配置列表
  getFrontSourceList()
})

// 获取配置列表
function getFrontSourceList () {
  isLoading.value = true
  frontSourceList({
    source,
    platform_id: props.platform
  }).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      dataSource.value = data
    } else {
      message.error(res.message || msg)
    }
  })
}

// 新增、编辑板块
function handleAdd (record) {
  RefAdd.value.showDrawer({
    // 当前平台
    platform: props.platform,
    // 单条记录id
    id: record ? record.id : undefined
  })
}

// 删除板块 
// function handleDelete (record) {
//   isLoading.value = true
//   const params = {
//     source,
//     id: record.id
//   }
//   frontSourceDelete(params).then(res => {
//     isLoading.value = false
//     const { code, msg, data } = res
//     if (code === 0) {
//       message.success('删除成功')
//       // 更新列表
//       getFrontSourceList()
//     } else {
//       message.error(res.message || msg)
//     }
//   })
// }

</script>

<style lang="less" scoped>
.tool-view {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 20px;
}
.table-view {
  :deep(.ant-table-cell) {
    vertical-align: top;
  }
  .play-name {
    &:not(:last-child) {
      margin-bottom: 28px;
    }
    .gap {
      margin: 0 8px;
    }
  }
}
a[disabled] {
  pointer-events: none;
  cursor: default;
}
</style>